import React, { useState } from "react";
import LoadingOverlay from 'react-loading-overlay';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Signupform() {
    const [step, setStep] = useState("email");  // Controls the current step (email, otp, or signup form)
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [full_name, setFullname] = useState("");
    const [company_name, setCompanyname] = useState("");
    const [phone, setPhone] = useState("");
    const [industry, setIndustry] = useState("");
    const [tenant_name, setTenantname] = useState("");
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);

    const siteKey = '6LeUFVQqAAAAAD2yeueZfDDmQnO69gLfQnx-_RO-'; 

    

    // Call OTP send API
    async function sendOTP() {
        if (!email) {
            Swal.fire({ title: "Error!", text: "Email is required.", icon: "error", timer: 3000 });
            return;
        }
        setIsLoading(true);
        try {
            const response = await fetch("https://demobrand.ciphercode.co/api/v1/sent-otp/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (response.ok) {
                Swal.fire({ title: "OTP sent!", text: "Please check your email.", icon: "success", timer: 3000 });
                setStep("otp");  // Move to the OTP step
            } else {
                const result = await response.json();
                Swal.fire({ title: "Error!", text: result.message || "Error sending OTP.", icon: "error", timer: 3000 });
            }
        } catch (error) {
            Swal.fire({ title: "Error!", text: "An error occurred. Please try again later.", icon: "error", timer: 3000 });
        } finally {
            setIsLoading(false);
        }
    }

    // Call OTP verify API
    async function verifyOTP() {
        if (!otp) {
            Swal.fire({ title: "Error!", text: "OTP is required.", icon: "error", timer: 3000 });
            return;
        }
        setIsLoading(true);
        try {
            const response = await fetch("https://demobrand.ciphercode.co/api/v1/verify-otp/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, otp })
            });

            if (response.ok) {
                Swal.fire({ title: "OTP verified!", text: "Proceed to complete the registration.", icon: "success", timer: 3000 });
                setStep("signup");  // Move to the signup form
            } else {
                const result = await response.json();
                Swal.fire({ title: "Error!", text: result.message || "Invalid OTP.", icon: "error", timer: 3000 });
            }
        } catch (error) {
            Swal.fire({ title: "Error!", text: "An error occurred. Please try again later.", icon: "error", timer: 3000 });
        } finally {
            setIsLoading(false);
        }
    }

    // Handle reCAPTCHA token
    function onCaptchaChange(token) {
        setCaptchaToken(token);
    }

    // Call tenant creation API
    async function auto_onboard() {
        if (!captchaToken) {
            Swal.fire({ title: "Error!", text: "Please complete the reCAPTCHA.", icon: "error", timer: 3000 });
            return;
        }

        const formData = new FormData();
        formData.append('full_name', full_name);
        formData.append('company_name', company_name);
        formData.append('industry', industry);
        formData.append('tenant_name', tenant_name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('captcha_token', captchaToken);  // Send the token to the backend

        if (full_name.trim() === "" || company_name.trim() === "" || email.trim() === "" || industry.trim() === "" || phone.trim() === "" || tenant_name.trim() === "") {
            setError(true);
        } else {
            setError(false);
            setIsLoading(true);
            try {
                const response = await fetch("https://demobrand.ciphercode.co/api/v1/create-tenant/", {
                    method: 'POST',
                    body: formData
                });
                const result = await response.json();

                if (response.ok) {
                    Swal.fire({
                        title: "Tenant creation request submitted!",
                        text: "Please check your email for further details.",
                        icon: "success",
                        timer: 5000
                    });
                    // Clear the form fields
                    setFullname("");
                    setCompanyname("");
                    setEmail("");
                    setPhone("");
                    setIndustry("");
                    setTenantname("");
                    setMessage("");
                    setStep("email");  // Reset to email step after successful signup
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: result.error || "An error occurred. Please try again later.",
                        icon: "error",
                        timer: 5000
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: "Error!",
                    text: "An error occurred. Please try again later.",
                    icon: "error",
                    timer: 5000
                });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <LoadingOverlay active={isLoading} spinner text='Processing...'>
            <div className="container signup_form">
                <h2>Register</h2>
                {step === "email" && (
                    <div>
                        <div className="row px-3">
                            <input type="email" className="mb-4" name="email" value={email} placeholder="Email Address" required onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="row px-3">
                            <button type="button" onClick={sendOTP}>Send OTP</button>
                        </div>
                    </div>
                )}
                {step === "otp" && (
                    <div>
                        <div className="row px-3">
                            <input type="text" className="mb-4" name="otp" value={otp} placeholder="Enter OTP" required onChange={(e) => setOtp(e.target.value)} />
                        </div>
                        <div className="row px-3">
                            <button type="button" onClick={verifyOTP}>Verify OTP</button>
                        </div>
                    </div>
                )}
                {step === "signup" && (
                    <form>
                        {error && <p style={{ color: 'red' }}>Please fill out all the required fields.</p>}
                        <div className="row px-3">
                            <input type="text" className="mb-4" name="fname" value={full_name} placeholder="Full Name" required onChange={(e) => setFullname(e.target.value)} />
                        </div>
                        <div className="row px-3">
                            <input className="mb-4" name="cname" value={company_name} placeholder="Company Name" required onChange={(e) => setCompanyname(e.target.value)} />
                        </div>
                        <div className="row px-3">
                            <input className="mb-4" type="number" name="phone" placeholder="Phone number" value={phone} required onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className="row px-3 select-container">
                            <select className="mb-4" name="industry" value={industry} onChange={(e) => setIndustry(e.target.value)} >
                                <option value="">Select industry</option>
                                <option value="">Select industry</option>
                                <option value="fashion">Fashion and Apparels</option>
                                <option value="personal">Personnel Care</option>
                                <option value="luxury">Luxury and Beauty</option>
                                <option value="spirit">Spirit and Wines</option>
                                <option value="electrical">Electrical and Electronics Appliances</option>
                                <option value="personnel">Personnel Accessories</option>
                                <option value="toys">Toys</option>
                                <option value="pharma">Pharma</option>
                                <option value="leather">Leather Accessories</option>
                                <option value="others">Others</option>
                            </select>
                        </div>
                        <div className="row px-3">
                            <input type="text" className="mb-4" name="Tname" value={tenant_name} placeholder="Tenant Name" required onChange={(e) => setTenantname(e.target.value)} />
                        </div>
                        <div className="row px-3">
                            <textarea type="text" className="mb-8" name="msg" placeholder="What problem are you trying to solve?" value={message} onChange={(e) => setMessage(e.target.value)} />
                        </div>
                        {/* Google reCAPTCHA */}
                        <div className="row px-3 recaptcha-container">
                            <ReCAPTCHA sitekey={siteKey}onChange={onCaptchaChange}/>
                        </div>
                        
                {/*          <div className="row px-3">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="acceptTerms" checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
                                <label className="form-check-label" htmlFor="acceptTerms">
                                    I accept the <span><Link to="/privacy">Terms of use</Link></span> & <span><Link to="/privacy">Privacy policy</Link></span>
                                </label>
                            </div>
                            <button type="button" onClick={auto_onboard} disabled={!acceptTerms || !captchaToken}>Submit</button>
                </div>  */ }
                        <div className="row px-3">
                            <button type="button" onClick={auto_onboard} disabled={!captchaToken}>Submit</button>
                        </div>
                    </form>
                )}
            </div>
        </LoadingOverlay>
    );
}
