import React from 'react'
import img10 from '../../images/Supported_Applications-transparent (1).png';
import img1 from '../../images/infosys.png';
import img2 from '../../images/sellerware logo.png';
import img3 from '../../images/Wondersoft.png';
import img4 from '../../images/Olabi Logo.png';
import img5 from '../../images/Shopify.png';
import Marquee from "react-fast-marquee";
import './support-app.css';



export default function supportapp() {
    return (
        <div className="sup-app">
            <div className="container application-content">
              <h2><span>Supported Applications!</span></h2>
              <h4>Seamless Integrations So you Don’t Have to Worry</h4>
              </div>
              <div className="marquee-slider">
              <Marquee speed={50} delay={0}>
          <div className="image_wrapper">
            <img src={img1} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img2} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img3} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img4} alt="" />
          </div>
          <div>
            <img src={img5} alt="" />
          </div>
          
        </Marquee>
        <br/><br/><br/><br/>
        </div>


              
            
        </div>
    )
}
