import React from 'react'

import Card from 'react-bootstrap/Card';
import img1 from '../../images/prod1.png';
import img2 from '../../images/prod2.png';
import img3 from '../../images/prod3.png';
import img4 from '../../images/prod4.png';
import img5 from '../../images/digi1.png';
import img6 from '../../images/digi2.png';
import img7 from '../../images/digi3.png';
import img8 from '../../images/qreach4.png';
import img9 from '../../images/personal1.png';
import img10 from '../../images/personal2.png';
import img11 from '../../images/personal3.png';
import img12 from '../../images/personal4.png';
import img13 from '../../images/mean1.png';
import img14 from '../../images/mean2.png';
import img15 from '../../images/mean3.png';
import img16 from '../../images/mean4.png';
import './prod-digi.css';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function prodDigi() {
    return (
        <div className="product">
            <div className="container prod-digi-content">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Product Digitization & Robust Authentication</span>
                    </h2>
                    <h2>
                        <span>QSeal</span>
                    </h2>
                </div>
               
                {/* Grid layout for larger screens only */}
                <div className=" row">
                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img1} />
                            <Card.Body className='product-body'>
                                <Card.Title>Counterfeit Protection</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Protect Customers form Fake
                                    Products that Damages Image
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img2} />
                            <Card.Body className='product-body'>
                                <Card.Title>Win Customer’s Trust</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Create Reliability and Faith
                                    within your Industry
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img3} />
                            <Card.Body className='product-body'>
                                <Card.Title>Interaction Data in Real
                                    Time</Card.Title>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Understand Customer’s
                                    Behavior towards your
                                    Products
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img4} />
                            <Card.Body className='product-body'>
                                <Card.Title>Supply Chain Traceability</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Digitize Products to Monitor
                                    each batch’s journey in transit
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />
                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Immersive Customer Engagement & Digital
                             Experiences</span>
                    </h2>
                    <h2>
                        <span>QReach</span>
                    </h2>
                </div>
                <div className="row">
                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img5} />
                            <Card.Body>
                                <Card.Title>16+ Campaign Options</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Create relevant, personalized
                                    & customized experiences
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img6} />
                            <Card.Body>
                                <Card.Title>Customer Insights &
                                    Analytics</Card.Title>
                                    <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Understand, Interact & Engage
                                    with Every Customer
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img7} />
                            <Card.Body>
                                <Card.Title>Real Time Action Alerts</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Build, manage & send
                                    transaction alerts through a
                                    single API
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img8} />
                            <Card.Body>
                                <Card.Title>Omnichannel
                                    Communication</Card.Title>
                                    <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Personalize & Connect on
                                    multiple impactful channels
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />

                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Personalized Story Telling & Loyalty Programs</span>
                    </h2>

                </div>
                <div className=" row">
                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img9} />
                            <Card.Body>
                                <Card.Title>Customer Journey Mapping</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Deliver relevant customer
                                    experiences at every stage
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img10} />
                            <Card.Body>
                                <Card.Title>Long Term Loyalty</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                Reduce Churn by sending
tailored offers, coupons, gift
cards, etc.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img11} />
                            <Card.Body>
                                <Card.Title>Sustainable Retention</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                Relieve Customer from Post
Sale Challenges, Let Brandwise
Take Care of it!
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img12} />
                            <Card.Body>
                                <Card.Title>Expand Wallet Share</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Reactivate dormant customers
                                    and Increase customer spend
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />
                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Meaningful Analytics & Rewarding Insights</span>
                    </h2>

                </div>
                <div className=" row">
                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img13} />
                            <Card.Body>
                                <Card.Title>Accurate Buyer Behavior</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Gather realistic and reliable
                                    data on customer preferences
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img14} />
                            <Card.Body>
                                <Card.Title>Manage Campaign ROI Data</Card.Title>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Mange and track multiple
                                    campaign performances at
                                    once
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img15} />
                            <Card.Body>
                                <Card.Title>Data That Matter To You</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Study the data that is tailored
                                    to your specific needs
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img16} />
                            <Card.Body>
                                <Card.Title>Effortless & User Friendly</Card.Title><br/>
                                <Card.Text className="main-text" style={{fontSize:"14px",color:"black"}}>
                                    Simple yet powerful UI so that
                                    you can get the most,
                                    effortlessly
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />

            </div><br /><br /><br />



        </div>
    )
}
